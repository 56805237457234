import {
  Component,
  Input,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'pl-spinner',
  templateUrl: './pl-spinner.component.html',
  styleUrls: ['./pl-spinner.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class PlSpinnerComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() color: string = '#FF5A60'; // PL brand color
  @Input() loading: boolean = false;

  @HostBinding('class')
  get hostClasses(): string {
    return `pl-spinner-component ${this.size} ${this.loading ? 'loading' : ''}`;
  }
}
