import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { PLProviderProfilePosterDialogComponent } from '../pl-provider-profile-poster-dialog/pl-provider-profile-poster-dialog.component';

@Component({
  selector: 'pl-provider-details-basic-information',
  templateUrl: './pl-provider-details-basic-information.component.html',
  styleUrls: [
    './pl-provider-details.common.less',
    './pl-provider-details-basic-information.component.less',
  ],
})
export class PLProviderDetailsBasicInformationComponent
  implements OnInit, OnChanges
{
  @Input() provider: any;
  @Input() userType: string;

  name: string;
  pronunciation: string;
  birthDay: string;
  timeZone: string;
  posterComplete: boolean = false;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.updateBasicInformation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.provider) {
      this.updateBasicInformation();
    }
  }

  openPosterDialog(): void {
    const providerWithUser = {
      ...this.provider,
      user: this.provider.user, // Use provider.user as the user ID for the image
    };
    this.dialog.open(PLProviderProfilePosterDialogComponent, {
      width: '90vw',
      maxWidth: '1200px',
      data: { providers: [providerWithUser] },
    });
  }

  getPosterIcon(): string {
    if (this.posterComplete) {
      return 'done';
    }
    return 'close';
  }

  private updateBasicInformation(): void {
    if (!this.provider) {
      return;
    }
    this.pronunciation = this.provider.name_pronunciation;
    this.timeZone = this.provider.timezone;
    if (this.provider.first_name && this.provider.last_name) {
      this.name = this.provider.first_name + ' ' + this.provider.last_name;
    }
    if (this.provider.birth_date) {
      const birthDate = dayjs(this.provider.birth_date);
      this.birthDay = birthDate.format('MMMM DD');
    }

    // Check if provider has Spanish enabled
    const hasSpanish = this.provider.languages?.some(
      (lang: { code: string }) => lang.code === 'es',
    );

    // Check English ice breaker questions
    const hasEnglishIceBreakers = !!(
      this.provider.ice_breaker_question_1 &&
      this.provider.ice_breaker_answer_1 &&
      this.provider.ice_breaker_question_2 &&
      this.provider.ice_breaker_answer_2 &&
      this.provider.ice_breaker_question_3 &&
      this.provider.ice_breaker_answer_3
    );

    // Check Spanish ice breaker questions if Spanish is enabled
    const hasSpanishIceBreakers =
      !hasSpanish ||
      !!(
        this.provider.ice_breaker_es_question_1 &&
        this.provider.ice_breaker_es_answer_1 &&
        this.provider.ice_breaker_es_question_2 &&
        this.provider.ice_breaker_es_answer_2 &&
        this.provider.ice_breaker_es_question_3 &&
        this.provider.ice_breaker_es_answer_3
      );

    // Set poster completion status
    this.posterComplete = hasEnglishIceBreakers && hasSpanishIceBreakers;
  }
}
